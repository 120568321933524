/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-children-prop */
import React, {
    useEffect, useState, useRef, createRef,
} from 'react';
import StarRatings from 'react-star-ratings';
import parser from 'html-react-parser';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import {
    downCurve,
    getStateName, processCertificateImages, upCurve, wrapperClass, eventBus,
} from '../Utils';
import API from '../services/API';

import Loading from '../components/elements/Loading';
import 'react-toastify/dist/ReactToastify.css';
import { AgentMap } from '../components/AgentMap';
import 'react-image-gallery/styles/css/image-gallery.css';

import AgentLabel from '../components/AgentLabel';
import AgentClientReview from '../components/AgentClientReview';
import AgentTestimonials from '../components/AgentTestimonials';
import BreadCrumb from '../components/BreadCrumb';

import sanitizeHtml from 'sanitize-html-react';

import AgentCertificates from '../components/AgentCertificate';
import AgentTravelStories from '../components/AgentTravelStories';
import AgentTravelSpecialities from '../components/AgentTravelSpecialities';
import AgentAreaOfExpertis from '../components/AgentAreaOfExpertis';
import AgentItineraries from '../components/AgentItineraries';

function Agent() {
    const { id } = useParams();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();

    const [links, setLinks] = useState([{
        label: 'Home',
        url: '/',
    },
    {
        label: 'Find a travel Agent',
        url: '/find_travel_agent',
    },
    ]);

    const [section, setSection] = useState(query.get('s') || '');
    const [expandedReviewId, setExpandedReviewId] = useState(null);

    const [loading, setLoading] = useState(false);
    const [agent, setAgent] = useState();
    const [certificates, setCertificates] = useState([]);
    const history = useHistory();
    const travelRef = useRef(null);
    const certificateRef = useRef(null);
    const testimonialRef = useRef(null);
    const myPlacesRef = useRef(null);
    const myPlacesContainerRef = useRef(null);
    const reviewsRef = useRef(null);
    const myAlbumRef = useRef(null);
    const selectedPlaceRef = useRef(null);
    const itinerariesRef = useRef(null);
    const [agentTitle, setAgentTitle] = useState('');
    const [agentText, setAgentText] = useState('');
    const [noAgentReturned, setNoAgentReturned] = useState(false);
    const executeScroll = (ref, block = 'start') => {
        ref.current.scrollIntoView({ behavior: 'smooth', block });
    };
    const executeScrollPlace = () => {
        // myPlacesContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setTimeout(() => {
            selectedPlaceRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 200);
    };

    const [albums, setAlbums] = useState([]);

    const [videos, setVideos] = useState([]);
    const [stories, setStories] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState();
    const [additionalBio, setAdditionalBio] = useState([]);
    const [itineraries, setItineraries] = useState([]);
    const [refs, setRefs] = useState([]);

    const prepareAdditionalBio = (data) => {
        if (data.agentAddlSpecializations.some((x) => x.addlBioId !== 0)) {
            setAdditionalBio(data.agentAddlSpecializations);
        }
    };
    const getAgentBio = () => {
        setLoading(true);
        API.agentBio({
            agentId: id,

        }).then((result) => {
            if (result.responseStatus === 1) {
                if (result.data.agentId !== 0) {
                    setAgent(result.data);
                    setCertificates(processCertificateImages(result.data));
                    prepareAdditionalBio(result.data);
                    getAgentAlbum();
                    getTravelStories();
                    getItineraries();

                    const drefs = {};
                    result.data.agentReviews.forEach((x) => {
                        drefs[x.reviewId] = createRef();
                    });
                    setRefs(drefs);
                }
                if (result.data.agentId === 0) {
                    setNoAgentReturned(true);
                }
            } else {
                toast(result.error.message);
            }
            setLoading(false);
        });
    };

    const getAgentAlbum = () => {
        setLoading(true);
        API.agentAlbum({
            agentId: id,

        }).then((result) => {
            if (result.responseStatus === 1) {
                setAlbums(result.data);
                getAgentVideos();
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getTravelStories = () => {
        setLoading(true);
        API.experimentalPost({
            agentId: id,
            bioId: 0,
            blogId: 0,
            returnAll: 'true',

        }).then((result) => {
            if (result.responseStatus === 1) {
                setStories(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getAgentVideos = () => {
        setLoading(true);
        API.agentVideos({
            agentId: id,

        }).then((result) => {
            if (result.responseStatus === 1) {
                setVideos(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getItineraries = () => {
        setLoading(true);
        API.agentItineraries({
            agentId: id,
            bioId: 0,
            returnAll: 'true',

        }).then((result) => {
            if (result.responseStatus === 1) {
                setItineraries(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    useEffect(() => {
        getAgentBio();
        window.scrollTo(0, 0);
    }, []);

    const constructAgentTitle = () => {
        if (agent.agentAddlSpecializations.length === 1) {
            setAgentTitle(agent.agentAddlSpecializations[0].displayTitle);
            setAgentText(agent.agentAddlSpecializations[0].bioText);
        } else if (agent.agentAddlSpecializations.length > 1) {
            setAgentTitle(agent.overviewTitle);
            setAgentText(agent.expertiseOverview);
        }
    };

    useEffect(() => {
        if (agent) {
            setLinks([{
                label: 'Home',
                url: '/',
            },
            {
                label: 'Find a travel Agent',
                url: '/find_travel_agent',
            }, {
                label: getStateName(agent.state),
                url: `/agents?state=${agent.state}`,
            },
            {
                label: `${agent.firstName} ${agent.lastName}`,
            }]);
            constructAgentTitle();
        }
    }, [agent]);

    useEffect(() => {
        if (section !== '' && reviewsRef.current) {
            if (section === 'review') {
                setTimeout(() => {
                    executeScroll(reviewsRef);
                }, 1000);
            }
        }
    }, [section, reviewsRef.current]);

    const agentBio = () => (
        <div className="flex flex-col gap-10 md:px-20  ">

            <div className="flex flex-col gap-4 px-4 ">
                <div className=" md:-ml-6 mt-4 md:mt-0" style={{ zIndex: '1000' }}>
                    <BreadCrumb links={links} />
                </div>
                <div className="flex flex-col md:flex-row gap-4 md:gap-6 md:items-center  w-full flex-1">

                    <div className="absolute right-0 z-40 top-28 md:top-32 hidden-tw md:block">
                        <img
                            alt="Vector"
                            src="/images/Vector 5.webp"
                            className="md:w-full -mr-40 md:mr-0"
                        />

                    </div>
                    <div className="flex flex-row gap-2">
                        <div className="w-56 h-72 hidden-tw md:block bg-blue-dark rounded-tr-3xl
             md:absolute -ml-6   z-10"
                        />

                        <img
                            alt={agent.firstName + ' ' + agent.lastName + ', ' + agent.city + ', ' + agent.state + ' Travel Agent'}
                            src={agent.agentPhotoFileName}
                            className="hidden-tw md:block md:w-80 md:mt-8 mx-auto  rounded-tr-3xl z-20 relative"
                        />
                        <div className="md:hidden w-full">

                            <AgentLabel agent={agent} reviewCallback={() => executeScroll(reviewsRef)} />
                        </div>

                    </div>
                    <div className="hidden-tw md:flex flex-col items-center md:items-start gap-2  w-full">
                        {/* <BreadCrumb links={links} /> */}
                        <AgentLabel agent={agent} reviewCallback={() => executeScroll(reviewsRef)} />

                    </div>

                </div>

            </div>
            <div className="flex flex-col gap-2 p-6 md:p-8 bg-gray-verylight md:rounded-l-3xl md:-mr-20">
                <div className="text-center md:text-left text-sm md:text-xl  font-bold text-blue-dark">{agentTitle}</div>
                <div className="text-gray-800 font-normal text-xs md:text-sm py-2">
                    {parser(sanitizeHtml(agentText))}
                </div>
            </div>
        </div>
    );

    const tabBar = () => {
        const item = 'border-r border-gray-400';
        const baseClass = 'inline-block text-white font-semibold  hover:text-white md:px-6 px-3 cursor-pointer hover:scale-105 transition transform  ease-in-out duration-500 text-xs md:text-sm';

        const separator = () => <hr className="w-1 bg-white hidden-tw" />;

        const displayTabBar = ((additionalBio.length > 0 ? 1 : 0)
            + ((certificates.length > 0 || agent.agentCertifications.length > 0) ? 1 : 0)
            + (agent.agentReviews.length > 0 ? 1 : 0)
            + (itineraries.length > 0 ? 1 : 0)
            + (agent.agentTestimonials.length > 0 ? 1 : 0)
            + (agent.agentMaps.length > 0 ? 1 : 0)) >= 2;

        return (
            <>
                {displayTabBar && (
                    <div className="md:px-5 py-2 bg-blue-dark md:flex md:flex-row md:text-center text-left  text-lg tracking-wider md:mt-20  md:justify-center">
                        {additionalBio.length > 0
                            && (
                                <>
                                    <div
                                        className={[item, baseClass, 'uppercase'].join(' ')}
                                        onClick={() => {
                                            executeScroll(travelRef);
                                        }}
                                    >
                                        TRAVEL SPECIALTIES

                                    </div>
                                    {separator()}
                                </>
                            )}
                        {agent.agentReviews.length > 0 && (
                            <>
                                <div
                                    className={[item, baseClass].join(' ')}
                                    onClick={() => {
                                        executeScroll(reviewsRef);
                                    }}
                                >
                                    REVIEWS

                                </div>
                                {separator()}
                            </>
                        )}

                        {(albums.length > 0 || videos.length > 0 || stories.length > 0)
                            && (
                                <>
                                    <div
                                        className={[item, baseClass].join(' ')}
                                        onClick={() => {
                                            executeScroll(myAlbumRef);
                                        }}
                                    >
                                        TRAVEL STORIES AND MEDIA

                                    </div>
                                    {separator()}
                                </>
                            )}
                        {itineraries.length > 0 && (
                            <>
                                <div
                                    className={[item, baseClass].join(' ')}
                                    onClick={() => {
                                        executeScroll(itinerariesRef);
                                    }}
                                >
                                    MY ITINERARIES

                                </div>
                                {separator()}
                            </>
                        )}

                        {(certificates.length > 0 || agent.agentCertifications.length > 0)
                            && (
                                <>
                                    <div
                                        className={[item, baseClass].join(' ')}
                                        onClick={() => {
                                            executeScroll(certificateRef);
                                        }}
                                    >
                                        CERTIFICATES AND AWARDS

                                    </div>
                                    {separator()}
                                </>
                            )}

                        {agent.agentMaps.length > 0
                            && (
                                <>
                                    <div
                                        className={[item, baseClass].join(' ')}
                                        onClick={() => {
                                            executeScroll(myPlacesRef);
                                        }}
                                    >
                                        MY PLACES

                                    </div>
                                    {separator()}
                                </>
                            )}

                        {agent.agentTestimonials.length > 0 && (
                            <>
                                <div
                                    className={[item, baseClass].join(' ')}
                                    onClick={() => {
                                        executeScroll(testimonialRef);
                                    }}
                                >
                                    TESTIMONIALS

                                </div>
                                {/* {separator()} */}
                            </>
                        )}

                    </div>
                )}
            </>
        );
    };

    const handleButtonClick = (reviewId) => {
        eventBus.dispatchEvent(new CustomEvent('toggleShowFull', { detail: { showFull: true } }));
        setExpandedReviewId((prevId) => (prevId === reviewId ? null : reviewId));
    };

    useEffect(() => {
        if (expandedReviewId) {
            const targetRef = refs[expandedReviewId];
            if (targetRef && targetRef.current) {
                targetRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [expandedReviewId, refs]);

    const agentMaps = () => (
        <div
            className={[wrapperClass, 'md:pr-0 bg-blue-dark md:bg-white px-4  md:px-20 relative overflow-x-hidden'].join(' ')}
            ref={myPlacesRef}
        >
            <div className="absolute right-0 w-1/3  -mr-32 top-0 hidden-tw md:block">{upCurve}</div>
            <div className="absolute left-0 w-1/3  -ml-32 bottom-10 hidden-tw md:block">{downCurve}</div>
            <div className="flex flex-col gap-4 mb-8 items-center md:items-start">
                <div className="text-xl md:text-4xl font-semibold px-4 md:px-0 text-center md:text-left text-white md:text-blue-dark">
                    My Places
                </div>
                <div className={['md:bg-blue-dark bg-white', 'h-1.5 w-24 md:w-14 md:ml-0'].join(' ')} />
            </div>
            <div className="flex flex-col md:flex-row  mt-10 w-full" ref={myPlacesContainerRef}>
                <div className="md:w-2/3 md:inline-block ">

                    <AgentMap
                        selectedLocation={selectedLocation}
                        callback={(val) => {
                            setSelectedLocation(val);
                        }}
                        locations={agent.agentMaps}
                        containerElement={<div className="w-full h-smallmap md:h-map" />}
                        mapElement={<div className="md:rounded-l-3xl" style={{ height: '100%' }} />}
                    />
                </div>
                <div className="md:w-1/3 inline-block text-white overflow-y-auto h-map bg-blue-dark">
                    {agent.agentMaps.map((x) => (
                        <div
                            key={x.mapId}
                            ref={selectedLocation && x.mapId === selectedLocation.mapId ? selectedPlaceRef : null}
                            className={['flex flex-row p-4 gap-4 justify-between', selectedLocation && selectedLocation.mapId === x.mapId ? 'bg-gray-500' : ''].join(' ')}
                        >
                            <div className="flex flex-col gap-2" title="Information">
                                <button
                                    type="button"
                                    title="reviews"
                                    className="text-sm md:text-base font-bold cursor-pointer focus:outline-none focus:ring focus:ring-offset-2 focus:ring-orange-500"
                                    onClick={() => setSelectedLocation(x)}
                                    style={{ all: 'unset' }}
                                >
                                    {`${x.locale} ${x.locale ? ',' : ''} ${x.area} ${x.region}`}
                                </button>
                                <div className="flex flex-row gap-2">
                                    {x.bookedIt && <div className="text-sm font-light">{`Booked It ${x.bookingCount} Times`}</div>}
                                    {x.beenThere && x.bookedIt && <div>|</div>}
                                    {x.beenThere && <div className="text-sm font-light">{`Been There ${x.visitCount} Times`}</div>}
                                </div>
                                <StarRatings
                                    rating={x.reviewRating}
                                    starRatedColor="#FD6A4A"
                                    numberOfStars={5}
                                    name="rating"
                                    starDimension="14px"
                                    starSpacing="2px"
                                />
                                <button
                                    type="button"
                                    title="reviews"
                                    className="text-sm font-light focus:outline-none focus:ring focus:ring-offset-2 focus:ring-orange-500"
                                    onClick={() => handleButtonClick(x.reviewId)}
                                    style={{ all: 'unset' }}
                                >
                                    {expandedReviewId === x.reviewId
                                        ? parser(sanitizeHtml(x.review))
                                        : parser(`${sanitizeHtml(x.review.slice(0, 50))}...<span className="mx-2 cursor-pointer">Read More</span>`)}
                                </button>

                                {x.locationTip && (
                                    <>
                                        <div className="text-sm font-semibold mt-4">Must Do:</div>
                                        <div className="text-sm font-light">{x.locationTip}</div>
                                    </>
                                )}
                                <div className={['bg-white', 'h-0.5 w-24 md:w-20 mt-4'].join(' ')} />
                            </div>
                            <div className="md:flex flex-col hidden">
                                {x.locationPhoto && (
                                    <img
                                        src={x.locationPhoto}
                                        className="object-contain h-48 w-full"
                                        alt="Location"
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                </div>


            </div>
        </div>
    );

    useEffect(() => {
        if (selectedLocation) { executeScrollPlace(); }
    }, [selectedLocation]);
    return (
        <div role="main">
            <div className=" max-w-screen-2xl mx-auto bg-white">
                <ToastContainer />
                {agent && (
                    <div className="h-full  flex flex-col pt-10 md:py-16 text-gray-900 ">
                        {agentBio()}
                        {tabBar()}
                        {additionalBio.length > 0
                            && (
                                <div ref={travelRef}>
                                    <AgentTravelSpecialities agent={agent} additionalBio={additionalBio} />
                                </div>
                            )}
                        <AgentAreaOfExpertis agent={agent} />
                        {agent.agentReviews.length > 0
                            && (
                                <div ref={reviewsRef}>
                                    <AgentClientReview agent={agent} refs={refs} setLoading={setLoading} callback={() => getAgentBio()} />
                                </div>
                            )}
                        {(albums.length > 0 || videos.length > 0 || stories.length > 0)
                            && (
                                <div ref={myAlbumRef}>
                                    <AgentTravelStories
                                        agent={agent}
                                        stories={stories}
                                        videos={videos}
                                        albums={albums}
                                    />
                                </div>
                            )}
                        {itineraries.length > 0
                            && (
                                <div ref={itinerariesRef}>
                                    <AgentItineraries agent={agent} itineraries={itineraries} />
                                </div>
                            )}
                        {(certificates.length > 0 || agent.agentCertifications.length > 0)
                            && (
                                <div ref={certificateRef}>
                                    <AgentCertificates certificates={certificates} agent={agent} />
                                </div>
                            )}

                        {agent.agentMaps.length > 0 && agentMaps()}

                        {agent.agentTestimonials.length > 0
                            && (
                                <div ref={testimonialRef}>
                                    <AgentTestimonials agent={agent} />
                                </div>
                            )}

                    </div>
                )}
                {noAgentReturned === true ? (
                    <>
                        <Helmet>
                            <meta name="robots" content="noindex, nofollow" />
                        </Helmet>
                        <div className="mx-4 md:mx-20 py-20 text-center h-screen">
                            <strong>
                                <h2 className="tl-cyan uppercase text-4xl tracking-wide text-blue-dark py-20">Sorry, this agent is unavailable</h2>
                            </strong>
                            <div>
                                <h3 className="uppercase text-xl my-2 font-semibold tracking-wide py-2">
                                    It looks like the agent you have selected is no longer a part of our network.
                                    <br />
                                    <a href="/find_travel_agent" className="tl-red hover:text-black ">Don&apos;t worry, we have plenty of other agents to choose from</a>
                                </h3>
                            </div>
                        </div>
                    </>
                ) : ''}
                {loading && <Loading />}
            </div>
        </div>

    );
}

export default Agent;
